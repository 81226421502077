import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/discount_app',
    name: 'discount_app',
    component: () => import('@/views/discount_app.vue')
  },
  {
    path: '/teachers_pc',
    name: 'teachers_pc',
    component: () => import('@/views/teachers_pc.vue')
  },
  {
    path: '/teachers_app',
    name: 'teachers_app',
    component: () => import('@/views/teachers_app.vue')
  },
  
  {
    path: '/discount_pc',
    name: 'discount_pc',
    component: () => import('@/views/discount_pc.vue')
  },
  {
    path: '/deposit_pc',
    name: 'deposit_pc',
    component: () => import('@/views/deposit_pc.vue')
  },
  {
    path: '/deposit_app',
    name: 'deposit_app',
    component: () => import('@/views/deposit_app.vue')
  },
  {
    path: '/gaoxiaoxuexi',
    name: 'gaoxiaoxuexi',
    component: () => import('@/views/gaoxiaoxuexi.vue')
  },
  {
    path: '/pengmin_app',
    name: 'pengmin_app',
    component: () => import('@/views/pengmin_app.vue')
  },
  {
    path: '/pengmin_pc',
    name: 'pengmin_pc',
    component: () => import('@/views/pengmin_pc.vue')
  },
  {
    path: '/teachersDay_app',
    name: 'teachersDay_app',
    component: () => import('@/views/teachersDay_app.vue')
  },
  {
    path: '/teachersDay_pc',
    name: 'teachersDay_pc',
    component: () => import('@/views/teachersDay_pc.vue')
  },
  {
    path: '/oneforone',
    name: 'oneforone',
    component: () => import('@/views/oneforone.vue')
  },
  {
    path: '/gaokao_pc',
    name: 'gaokao_pc',
    component: () => import('@/views/gaokao_pc.vue')
  },
  {
    path: '/gaokao_app',
    name: 'gaokao_app',
    component: () => import('@/views/gaokao_app.vue')
  },
  {
    path: '/spring_pc',
    name: 'spring_pc',
    component: () => import('@/views/spring_pc.vue')
  },
  {
    path: '/spring_app',
    name: 'spring_app',
    component: () => import('@/views/spring_app.vue')
  },


  
]

const router = new VueRouter({
  routes
})

export default router
